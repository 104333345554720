<template>
  <div class="stu-info-fill-main">
    <form-main-title :id="templateInfo.id" :title="templateInfo.archivesName"></form-main-title>
    <!-- 更新型 -->
    <StuInfoForm
      v-if="templateInfo.updateType == '2'"
      :key="templateInfo.id"
      :mode="mode"
      :school-id="schoolId"
      :stu-basic-info="stuBasicInfo"
      :template-info="templateInfo"
    />
    <!-- 累加型 -->
    <StuInfoTable
      v-else-if="templateInfo.updateType == '1'"
      :key="templateInfo.id"
      :mode="mode"
      :school-id="schoolId"
      :stu-basic-info="stuBasicInfo"
      :template-info="templateInfo"
    />
  </div>
</template>

<script>
import FormMainTitle from "@/components/scrollWrapper/Sub/FormMainTitle/index.vue";
import StuInfoForm from "./Form.vue";
import StuInfoTable from "./Table.vue";

export default {
  name: 'StuInfoFillMain',
  components: {
    FormMainTitle,
    StuInfoForm,
    StuInfoTable
  },
  props: [
    'mode',
    'schoolId',
    'stuBasicInfo',
    'templateInfo'
  ],
  data () {
    return {}
  },
  filters: {},
  computed: {},
  watch: {},
  created () {
    console.log(this.stuBasicInfo,'stuBasicInfo')
    console.log(this.templateInfo,'templateInfo');
  },
  mounted () { },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.stu-info-fill-main {
  padding: 15px 0;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
}

// 有分割线的标题
::v-deep .line-title {
  text-align: center;
  display: flex;
  flex-direction: row;
}
::v-deep .line-title:before {
  content: "";
  flex: 1 1;
  width: 400px;
  margin: auto 20px;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(135deg, #ffffff, #ffffff),
    linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
}
::v-deep .line-title:after {
  content: "";
  flex: 1 1;
  width: 400px;
  margin: auto 20px;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(135deg, #ffffff, #ffffff),
    linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
  border-bottom: 1px solid;
  border-image: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0) 0%,
    #e6e6e6 100%
  );
}
</style>
